<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Add Event
      </h1>

      <div class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4">
        <!-- component -->
        <form method="post" @submit.prevent="addNew">
          <div class="flex flex-col">
            <div class="mb-6" id="message"></div>
            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="event"
              >
                Event
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="name"
                type="text"
                placeholder="Event"
                v-model="name"
              />
              <p class="text-red text-xs italic" v-if="error && !name">
                Please enter a Offer.
              </p>
            </div>

            
            <div class="flex items-center justify-between">
              <button
                v-if="!loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Submit
              </button>
              <button
                v-if="loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                type="submit"
                disabled
              >
                Submitting...
              </button>
            </div>
          </div>
        </form>



        <div id="recipients" class="">
          
        </div>

      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "AddOffers",
  data() {
    return {
      sidebarOpen: true,
      name: "",
      seats: "",
      selection: "",
      error: false,
      loading: false
    };
  },
  methods: {
    addNew() {
      this.error = false;
      if (!this.name) {
        this.error = true;
        return;
      }
      this.loading = true;
      this.$axios
        .post(`https://spaceseats.io/api/price/add/`, {
           name: this.name,
           seats: this.seats,
           selection: this.selection,
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        },
        mode: 'no-cors',
      })
        .then(res => {
          console.log(res);
          this.loading = false;
          if (res.data.success) {
            // document.getElementById("message").innerHTML = `
            //   <span style="color:#3c763d;font-weight:bold">Operator added success</span>
            // `;
            // this.name = "";
              var html = "<table id='tn-tickets' class='border-collapse w-full text-sm'><tr><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Onsale Start Date-Time</th><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Onsale End Date-Time</th><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Presale Start Date-Time</th><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Presale End Date-Time</th></tr> <tbody id='ticketsList'>";
              
                html += '<tr>';
                html += '<td>'+res.data.data.public.startDateTime+'</td>'+'<td>'+res.data.data.public.endDateTime+'</td>'+'<td>'+res.data.data.presales[0].startDateTime+'</td>'+'<td>'+res.data.data.presales[0].endDateTime+'</td>';
                html += '</tr>';
              
              html += "</tbody></table>";
              
              document.getElementById("recipients").innerHTML = html;

            
            
          } else {
            document.getElementById("message").innerHTML = `
              <span style="color:#a94442;font-weight:bold">${res.data.message}</span>
            `;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          document.getElementById("message").innerHTML = `
              <span style="color:#a94442;font-weight:bold">Something went wrong</span>
            `;
        });
      setTimeout(() => {
        document.getElementById("message").innerHTML = ``;
      }, 6000);
    }
  }
};
</script>
